import React, { Component } from 'react';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import classNames from 'classnames/bind';
import Swiper from 'react-id-swiper';
import Ionicon from 'react-ionicons';

import styles from './Panama.module.scss';
import StorySectionTitle from '../../misc/StorySectionTitle';

const cx = classNames.bind(styles);

const episodes = [
  { name: '커피 헌팅의 시작' },
  { name: '관리된 커피 산지의 선정' },
  { name: '높은 품질을 위한 노력' },
  { name: '특별한 곳을 만나다' },
];

export default class Panama extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEpisodeIndex: 0,
    };
  }

  render() {
    const params = {
      effect: 'fade',
      slidesPerView: 1,
      spaceBetween: 8,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      on: {
        slideChange: () => {
          this.setState({
            currentEpisodeIndex: this.swiper.activeIndex,
          });
        },
      },
    };

    return (
      <ScreenClassRender
        render={screenClass => {
          const isMobile = ['xs', 'sm', 'md'].includes(screenClass);

          return (
            <div className={cx(['episodeSection', { mobile: isMobile }])}>
              <Container>
                <Row align="start">
                  <Col xs={12} lg={4} offset={{ lg: 1 }} className={cx('column')}>
                    <StorySectionTitle
                      title={
                        <span>
                          The Story of
                          <br />
                          artisée Coffee
                        </span>
                      }
                      subtitle={
                        <span>
                          아티제만의 특별한
                          <br />
                          블렌드 커피를 만들어내기 위한
                          <br />
                          아티제의 커피 산지 여행
                        </span>
                      }
                    />
                    <div className={cx('episodes')}>
                      {episodes.map((episode, i) => {
                        const { currentEpisodeIndex } = this.state;
                        const isActive = currentEpisodeIndex === i;

                        return (
                          <div key={String(i)} className={cx('episode', { active: isActive })}>
                            <div className={cx('angle')}>
                              {isActive && <Ionicon icon="ios-arrow-forward" fontSize="17px" />}
                            </div>
                            <div className={cx('name')} onMouseOver={this._handleMouseOver.bind(this, i)}>
                              {episode.name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                  <Col xs={12} lg={6} className={cx('column')}>
                    <Swiper
                      {...params}
                      ref={node => {
                        if (node) this.swiper = node.swiper;
                      }}>
                      <div>
                        <img
                          src={require('assets/images/story/coffee/panama-1.jpg')}
                          alt="커피 헌팅의 시작"
                          width="100%"
                        />
                      </div>
                      <div>
                        <img
                          src={require('assets/images/story/coffee/panama-2.jpg')}
                          alt="관리된 커피 산지의 선정"
                          width="100%"
                        />
                      </div>
                      <div>
                        <img
                          src={require('assets/images/story/coffee/panama-3.jpg')}
                          alt="높은 품질을 위한 노력"
                          width="100%"
                        />
                      </div>
                      <div>
                        <img
                          src={require('assets/images/story/coffee/panama-4.jpg')}
                          alt="특별한 곳을 만나다"
                          width="100%"
                        />
                      </div>
                    </Swiper>
                  </Col>
                </Row>
              </Container>
            </div>
          );
        }}
      />
    );
  }

  _handleMouseOver = index => {
    this.swiper.slideTo(index);
  };
}
