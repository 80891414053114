import React from 'react';
import classNames from 'classnames/bind';
import { ScreenClassRender } from 'react-grid-system';
import PropTypes from 'prop-types';

import styles from './StoryItem.module.scss';
import Paragraph from './Paragraph';

const cx = classNames.bind(styles);

const StoryItem = ({ title, subtitle, children }) => (
  <ScreenClassRender render={(screenClass) => {
    const isMobile = ['xs', 'sm', 'md'].includes(screenClass);

    return (
      <div className={cx({ mobile: isMobile })}>
        <div className={cx('title')}>{title}</div>
        <div className={cx('subtitle')}>{subtitle}</div>
        <Paragraph>{children}</Paragraph>
      </div>
    );
  }} />
);

StoryItem.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]).isRequired,
  subtitle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]).isRequired,
  children: PropTypes.string.isRequired,
};

export default StoryItem;