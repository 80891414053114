import React from 'react';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import classNames from 'classnames/bind';

import styles from './index.module.scss';
// import Blend from './Blend';
import Specialty from './Specialty';
import Panama from './Panama';
import StoryItem from '../../misc/StoryItem';
import ScaleImageCell from '../../misc/ScaleImageCell';

const cx = classNames.bind(styles);

const beanImage = (
  <img
    src={require('assets/images/story/coffee/clip-3.png')}
    alt="High Rank 10%"
    width="100%"
    className={cx('desktopPadding')}
  />
);

const CoffeeStory = () => (
  <ScreenClassRender
    render={screenClass => {
      const isMobile = ['xs', 'sm', 'md'].includes(screenClass);

      return (
        <div className={cx('contentWrapper')}>
          <Container className={cx('featureSection')}>
            <Row align="center">
              <Col xs={12} lg={4.5} offset={{ lg: 1 }} className={cx('column')}>
                <ScaleImageCell image={require('assets/images/story/coffee/clip-1.jpg')} ratio={536 / 521} />
              </Col>
              <Col xs={12} lg={5} offset={{ lg: 0.5 }} className={cx('column')}>
                <StoryItem
                  title={
                    <span>
                      SCAA
                      <br />
                      Score 80
                    </span>
                  }
                  subtitle={
                    <span>
                      Specialty Coffee
                      <br />
                      엄격한 기준 <strong>·</strong> SCAA <strong>·</strong> 80점
                    </span>
                  }>
                  미국 스페셜티 커피협회(SCAA)의 엄격한 분류기준이 적용되는 스페셜티 커피. 재배에서 수확, 결점두의
                  종류와 갯수, 생두의 신선도, 수분율, 커핑 시의 향미 모두가 특별해야만 얻을 수 있는 자격. 80점 이상의
                  커핑 점수를 통과한 커피만을 ‘스페셜티 커피’라 한다.
                </StoryItem>
              </Col>
            </Row>
            <Row align="center" className={cx([{ desktopPadding: !isMobile }])}>
              <Col xs={12} lg={4.5} offset={{ lg: 1 }} className={cx('column')}>
                {isMobile && beanImage}

                <StoryItem
                  title={
                    <span>
                      High
                      <br />
                      Rank 10%
                    </span>
                  }
                  subtitle={
                    <span>
                      한 알의 열매, 한 잔의 스페셜티 커피
                      <br />
                      상위 10% <strong>·</strong> 테루아 <strong>·</strong> 특별함
                    </span>
                  }>
                  전세계 생산량의 8 ~ 10%만 인정받는 스페셜티 커피. 와인만큼이나 중요한 커피의 테루아. 자연이 만든 한
                  알의 원두에 인간은 그저 겸손하게 그 특별함을 음미한다.
                </StoryItem>

                {!isMobile && beanImage}
              </Col>
              <Col xs={12} lg={5} offset={{ lg: 0.5 }} className={cx('column')}>
                <ScaleImageCell
                  image={require('assets/images/story/coffee/clip-2.jpg')}
                  ratio={294 / 521}
                  className={cx(['esmeralda1600', { mobile: isMobile }])}
                />
                <StoryItem title="Story" subtitle={<span>아티제 시그니처 블렌드 스토리</span>}>
                  아티제 시그니처 블렌드는 천혜의 자연에서 재배한 뛰어난 품질의 신선한 그린빈을 직접 찾아 수차례의
                  테스트를 거쳐 최상의 커피를 낼 수 있는 블렌드가 탄생했다. 매해 새로이 찾은 다양한 그린빈으로 자연이
                  주는 특별함을 에스프레소 한 잔에 담았다.
                </StoryItem>
              </Col>
            </Row>
          </Container>
          {/* <Blend /> */}
          <Specialty />
          <Panama />
        </div>
      );
    }}
  />
);

export default CoffeeStory;
