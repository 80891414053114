import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './StorySectionTitle.module.scss';

const cx = classNames.bind(styles);

const StorySectionTitle = ({ title, subtitle }) => (
  <div>
    <div className={cx('title')}>{title}</div>
    <div className={cx('subtitle')}>{subtitle}</div>
  </div>
);

StorySectionTitle.propTypes = {
  title: PropTypes.object.isRequired,
  subtitle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]).isRequired,
};

export default StorySectionTitle;