import React from 'react';
import { ScreenClassRender } from 'react-grid-system';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Jumbotron.module.scss';

const cx = classNames.bind(styles);

const Jumbotron = ({ engTitle, korTitle, image, content }) => (
  <ScreenClassRender render={(screenClass) => {
    const isMobile = ['xs', 'sm', 'md'].includes(screenClass);

    return (
      <div className={cx(['container', { mobile: isMobile }])} style={{ backgroundImage: `url(${image})` }}>
        <div className={cx(['wrapperStyle', { noBr: isMobile }])}>
          <div className={cx('engTitleStyle')}>{engTitle}</div>
          <div className={cx('korTitleStyle')}>{korTitle}</div>
          <div className={cx('paragraphStyle')}>{content}</div>
        </div>
      </div>
    );
  }} />
);

Jumbotron.propTypes = {
  engTitle: PropTypes.string.isRequired,
  korTitle: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
};

export default Jumbotron;