import React from 'react';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import classNames from 'classnames/bind';

import styles from './Specialty.module.scss';
import Paragraph from '../../misc/Paragraph';
import StorySectionTitle from '../../misc/StorySectionTitle';

const cx = classNames.bind(styles);

const Specialty = () => (
  <ScreenClassRender
    render={screenClass => {
      const isMobile = ['xs', 'sm', 'md'].includes(screenClass);
      const noBr = ['xs', 'sm', 'md'].includes(screenClass);

      return (
        <Container className={cx(['specialtySection', { center: isMobile }])}>
          <Row align="start">
            <Col xs={12} lg={5} offset={{ lg: 1 }} className={cx('column')}>
              <StorySectionTitle
                title={
                  <span>
                    artisée
                    <br />
                    Specialty Coffee
                  </span>
                }
                subtitle="아티제, 스페셜을 담다."
              />
            </Col>
            <Col xs={12} lg={5} className={cx('column')}>
              <div className={cx(['content', { noBr }])}>
                <div className={cx('headline')}>아티제 바리스타가 직접 선택한 블렌드</div>
                <Paragraph>
                  수십 가지 블렌드 후보 중 아티제 바리스타들이 직접 선택한 아티제 시그니처 블렌드는 살구의 은은한 산미와
                  견과류의 고소함이 느껴지는 부드럽고 깔끔한 블렌드로 모두 스페셜티 커피만을 사용하여 황홀한 맛과 향이
                  어우러져 피어난다.
                </Paragraph>
              </div>
            </Col>
          </Row>
        </Container>
      );
    }}
  />
);

export default Specialty;
