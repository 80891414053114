import React from 'react';

import CoffeeStory from 'components/Story/CoffeeStory';
import Jumbotron from 'components/misc/Jumbotron';
import TabBar from 'components/misc/TabBar';

const tabBarMenus = [
  { name: '브랜드 스토리', link: '/the-story/brand-story/' },
  { name: '커피 스토리', link: '/the-story/coffee-story/' },
];

const CoffeeStoryPage = () => (
  <div>
    <Jumbotron
      engTitle="Coffee Story"
      korTitle="커피 스토리"
      image={require('../../assets/images/banner/story.jpg')}
      content={
        <span>
          아티제 블렌드는 모두가 specialty 입니다.
          <br />
          스페셜티 커피의 고급스럽고 세련된 풍미가 조화롭게 어우러져
          <br />
          마치 만개된 꽃향기와 잘 익은 열매의 과즙처럼
          <br />
          황홀한 맛과 향이 어우러져 피어나는 아티제 아티제 시그니처 블렌드를 만나보세요.
        </span>
      }
    />
    <TabBar index={1} data={tabBarMenus} />
    <CoffeeStory />
  </div>
);

export default CoffeeStoryPage;
